/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import {returnmodules} from './modules'

import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'

// Array of sections
let menus = new Array()
menus.push(...returnmodules)
if (process.env.NODE_ENV == 'development') {
    menus.push(...dashboard)
    menus.push(...appsAndPages)
    menus.push(...uiElements)
    menus.push(...formAndTable)
    menus.push(...chartsAndMaps)
}
export default menus
