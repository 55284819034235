<template>
  <b-navbar-nav style="display: flex;list-style: none;">
    <b-nav-item>
      <feather-icon
          icon="ChevronsLeftIcon"
          class="mr-1"
          size="21"
          @click="()=>{$router.go(-1)}"
      />
    </b-nav-item>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem,
} from 'bootstrap-vue'

export default {
  components: {
    BNavbarNav, BNavItem,
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;
}
</style>
