<template>
    <div :class="myshark">

        <b-nav-item-dropdown
                class="dropdown-notification mr-25"
                menu-class="dropdown-menu-media"
                right
                @show="flag = true"
        >
            <template #button-content>
                <feather-icon
                        :badge="notificationlength"
                        :badge-classes="badge"
                        class="text-body"
                        icon="BellIcon"
                        size="21"
                />
            </template>

            <div v-if="flag">
                <!-- Header -->
                <li class="dropdown-menu-header">
                    <div class="dropdown-header d-flex">
                        <h4 class="notification-title mb-0 mr-auto">
                            待办任务信息
                        </h4>
                        <b-badge
                                pill
                                variant="light-primary"
                        >
                            {{notificationlength}} New
                        </b-badge>
                    </div>
                </li>
                <!-- Notifications -->
                <vue-perfect-scrollbar
                        :settings="perfectScrollbarSettings"
                        class="scrollable-container media-list scroll-area"
                        tagname="li"
                >
                    <!-- Account Notification -->
                    <!-- 通知事项下拉 -->

                    <b-link
                            v-for="(notifi,index) in  notification"
                            :key="index"
                            :to="{ name: 'apps-todo', query: { status: 2}}"
                            @click.native="flag = false"
                    >
                        <b-media>
                            <template #aside>
                                <b-avatar
                                        size="32"
                                        :src="notifi.avatar"
                                        :text="notifi.avatar"
                                        :variant="notifi.type"
                                />
                            </template>
                            <p class="media-heading info-title" style="width: 350px">
            <span class="font-weight-bolder info-title">
              #{{ notifi.id }} [{{notifi.type}}] {{ notifi.title }}
            </span>
                            </p>

                            <p class="media-heading info-titleto" style="width: 350px">
                                <small class="notification-text">{{ notifi.content }}</small>
                            </p>

                        </b-media>
                    </b-link>
                </vue-perfect-scrollbar>
            </div>

        </b-nav-item-dropdown>
    </div>
</template>

<script>
    import {
        BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
    } from 'bootstrap-vue'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import Ripple from 'vue-ripple-directive'
    import axios from "@axios"

    import store from "@/store";
    import notificationStore from "@/views/apps/notification/notificationStore";
    import {onUnmounted} from "@vue/composition-api";
    import todoStore from "@/views/apps/todo/todoStore";


    export default {
        data() {
            return {
                notification: [],
                notificationlength: '',
                flag: false,
                myshark: '',
                badge: '',
            }
        },
        components: {
            BNavItemDropdown,
            BBadge,
            BMedia,
            BLink,
            BAvatar,
            VuePerfectScrollbar,
            BButton,
            BFormCheckbox,
        },
        directives: {
            Ripple,
        },
        setup() {
            // Register module
            if (!store.hasModule('notification')) store.registerModule('notification', notificationStore)
            if (!store.hasModule('todo')) store.registerModule('todo', todoStore)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule('notification')) store.unregisterModule('notification')
            })

            const perfectScrollbarSettings = {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            }

            store.commit('notification/updateLength',0)
            store.commit('notification/updateItem',"")

            return {
                perfectScrollbarSettings,
            }
        },
        created() {
            store.dispatch('todo/ListStatus', {}).then(res => {
                let list = res.data.data
                store.commit('notification/updateLength',list.length)
                list.splice(5)
                store.commit('notification/updateItem',list)
            })
        },
        computed: {
            getNotification() {
                return notificationStore.state.notificationlength
            },
        },
        watch: {
            //监听getNotification变化
            getNotification() {
                // 监听当有新的通知发送来时抖动
                if (notificationStore.state.notificationlength >= this.notificationlength && notificationStore.state.notificationlength != 0) {
                    this.myshark = 'my-shark'
                    this.badge = 'bg-danger badge-glow'
                    setTimeout(() => {
                        this.myshark = ''
                        this.badge = 'bg-danger'
                    }, 2000)
                }
                this.notification = notificationStore.state.notification
                this.notificationlength = notificationStore.state.notificationlength
            }
        },
        methods: {},
        mounted() {
        }
    }
</script>

<style>
    .my-shark {
        animation: mymove 1s infinite;
    }

    @keyframes mymove {
        0% {
            transform: scale(1);
        }
        10%, 20% {
            transform: scale(0.9) rotate(-3deg);
        }
        30%, 50%, 70%, 90% {
            transform: scale(1.1) rotate(3deg);
        }
        40%, 60%, 80% {
            transform: scale(1.1) rotate(-3deg);
        }
        100% {
            transform: scale(1) rotate(0);
        }
    }

    .info-title {
        /*color: #ea5419;*/
        overflow: hidden; /*溢出的部分隐藏*/
        white-space: nowrap; /*文本不换行*/
        text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
    }

    .info-titleto {
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>